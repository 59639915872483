export const DOMAIN_STAGE_MAP: { [key: string]: string } = {
    'localhost': 'dev',
    'alpha.boxnpaper.com': 'alpha',
    'www.alpha.boxnpaper.com': 'alpha',
    'beta.boxnpaper.com': 'beta',
    'www.beta.boxnpaper.com': 'beta',
    'gamma.boxnpaper.com': 'gamma',
    'www.gamma.boxnpaper.com': 'gamma',
    'boxnpaper.com': 'prod',
    'www.boxnpaper.com': 'prod',
};
