
interface IApiConfig {
  endpoint: string;
  apiKey: string;
}

export const API_CONFIG: {
  [key: string]: {
    boxnpaperApi: IApiConfig
  }
} = {
  dev: {
    boxnpaperApi: {
      endpoint: 'https://qv1zldnc3b.execute-api.us-east-1.amazonaws.com/prod',
      apiKey: 'oSItXTSmCm4XlU71epFSF3RSY9cQZzUi8umcG9X1'
    }
  },
  alpha: {
    boxnpaperApi: {
      endpoint: 'https://qv1zldnc3b.execute-api.us-east-1.amazonaws.com/prod',
      apiKey: 'oSItXTSmCm4XlU71epFSF3RSY9cQZzUi8umcG9X1'
    }
  },
  beta: {
    boxnpaperApi: {
      endpoint: 'https://abqjwo1wub.execute-api.us-east-1.amazonaws.com/prod',
      apiKey: 'HVZ4CkWNkq30qA3M1RYrg1COHBBxJItr4OaWVbGJ'
    }
  },
  gamma: {
    boxnpaperApi: {
      endpoint: 'https://g61fxc0pd0.execute-api.us-east-1.amazonaws.com/prod',
      apiKey: 'xWkGRbd6Zq17Z6AgOiuFcdeLWsqOGF79HFs9cmXj'
    }
  },
  prod: {
    boxnpaperApi: {
      endpoint: 'https://y3jt0cluk3.execute-api.us-east-1.amazonaws.com/prod',
      apiKey: '8DhAseAi4xaPb1URLlekD50jBVcyuXOb7xnQyBj9'
    }
  }
}