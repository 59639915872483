import { API } from 'aws-amplify';
import {
  ForumPost,
  ForumPostThumbnail,

} from '../../components/ForumPostThumbnail';
import {
  Box,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { APP_PATH } from '../../path';


export default function WatchListing() {
    const navigate = useNavigate();
  
    const [loading, setLoading] = useState(false);
    const [forumPostList, setForumPostList] = useState<ForumPost[]>([]);
  
    useEffect(() => {
      const getWatches = async () => {
        setLoading(true);
        try {
          const data = await API.get('boxnpaper', '/content/post/list', {});
          data.sort(
            (w1: ForumPost, w2: ForumPost) =>
              w2.updatedTime - w1.updatedTime
          );
          setForumPostList(data);
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      };
      getWatches();
    }, []);
  
    if (loading) {
      return (
        <Container sx={{ height: '80vh' }}>
          <LoadingSpinner />
        </Container>
      );
    }
  
    return (
      <>
        <Box padding={{ xs: 1, sm: 1, md: 3 }}>
          <Grid container spacing={{ xs: 1, sm: 2, md: 4 }}>
            {forumPostList.map((forumPost, i) => (
              <Grid item xs={6} sm={4} md={3} key={i}>
                <ForumPostThumbnail
                  forumPost={forumPost}
                  onThumbnailClick={() => {
                    navigate(
                      APP_PATH.FORUM_POST_DETAIL.replace(':contentId', forumPost.contentId)
                    );
                  }}
                />
              </Grid>
            ))}
          </Grid>
          {forumPostList.length === 0 && (
            <Typography textAlign="center" marginTop="16px">
              No news articles
            </Typography>
          )}
        </Box>
        {forumPostList.length > 0 && (
          <Box
            padding={{ xs: 3, sm: 5 }}
            paddingBottom={{ xs: 6, sm: 10 }}
            display="flex"
            sx={{
              alignContent: 'center',
              justifyContent: 'center'
            }}
          >
            {/* Pagination code goes here */}
          </Box>
        )}
      </>
    );
  }
