import { Container } from '@mui/material';
import styled from 'styled-components';

export const PageContainer = styled(Container)`
  background-color: #fff;
  padding: 0px !important;
  min-height: 100vh;
`;

export const TransparentPageContainer = styled(Container)`
  padding: 0px !important;
  min-height: 100vh;
`;
