import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Link,
  Typography
} from '@mui/material';
import { API } from 'aws-amplify';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { APP_PATH } from '../../path';
import { appStore, IBoxnpaperUser } from '../../stores/appStore';
import { LoadingSpinner } from '../LoadingSpinner';
import { Comment } from './Comment';
import { CommentInput } from './CommentInput';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { COLORS } from '../../constants/Colors';

export interface ICommentsProps {
  channelId: string;
  sellerId: string;
}

export interface IComment {
  channelId: string;
  commentId: string;
  replyTo?: string;
  buyerReview?: boolean;
  content: string;
  createdAt: number;
  from: string;
  fromUserData: IBoxnpaperUser;
  likedBy?: string;
  editedBy?: string;
  deleted?: boolean;
  deletedByAdmin?: boolean;
}

export const Comments = observer((props: ICommentsProps) => {
  const { boxnpaperUser } = appStore;

  const { channelId, sellerId } = props;

  const [comments, setComments] = useState<{
    comments: IComment[];
    isLoading: boolean;
  }>({
    comments: [],
    isLoading: true
  });
  const [isLoading, setIsLoading] = useState(false);
  const [closedMap, setClosedMap] = useState<{ [key: string]: boolean }>({});

  const [oneSecondTimerFired, setOneSecondTimerFired] = useState(false);

  var flipFlop = false;
  var flipFlop30 = false;

  var commentsRefreshRate = 5;
  var commentsRefreshTimer = commentsRefreshRate;
  var isRefreshingComments = false;

  var debug = false;
  
  const [refreshCommentsState, setRefreshCommentsState] = useState(false);
  
  function fireOneSecondTimer() {
    if (debug) console.log("fireOneSecodnTimer");
  
    if (false) {
      clearInterval(oneSecondTimer);
    }
    if (debug) console.log("1sec timer fired ", commentsRefreshTimer);
    flipFlop = !flipFlop;
    setOneSecondTimerFired(flipFlop);
    if (debug) console.log("oneSecondTimerFired ", oneSecondTimerFired);
    if (commentsRefreshTimer > 0) {
      commentsRefreshTimer--;
    } else {
      commentsRefreshTimer = commentsRefreshRate;
      if (debug) console.log("thirty second timer fired");
      if (!isRefreshingComments) {
        isRefreshingComments = true;
        if (debug) console.log("refreshing bids");
  
        API.get('boxnpaper', '/comment', {
          queryStringParameters: {
            channelId
          }
        }).then((comments: IComment[]) => {
          setComments({
            comments,
            isLoading: false
          });
          isRefreshingComments = false;
          flipFlop30 = !flipFlop30;
          setRefreshCommentsState(flipFlop30);
          if (debug) console.log("done refreshing comments");
        });
      }
    }
  }
  
  var oneSecondTimer: NodeJS.Timeout;

  useEffect(() => {
    API.get('boxnpaper', '/comment', {
      queryStringParameters: {
        channelId
      }
    }).then((comments: IComment[]) => {
      setComments({
        comments,
        isLoading: false
      });
    });

    oneSecondTimer = setInterval(function() {
      fireOneSecondTimer();
    }, 1000)    

    return () => {
      clearInterval(oneSecondTimer);
    };

  }, []);

  const flatComments = comments.comments.sort(
    (c1, c2) => c1.createdAt - c2.createdAt
  );

  const postComment = async (
    content: string,
    replyTo?: string,
    callback?: () => void
  ) => {
    setIsLoading(true);
    const postedComment: IComment = await API.post('boxnpaper', '/comment', {
      body: {
        channelId,
        content,
        replyTo
      }
    });
    setComments({
      comments: [
        {
          ...postedComment,
          fromUserData: boxnpaperUser!
        },
        ...flatComments
      ],
      isLoading: false
    });
    callback?.();
    setIsLoading(false);
  };

  const likeComment = async (commentId: string) => {
    const updatedComment: IComment = await API.post(
      'boxnpaper',
      '/comment/like',
      {
        body: {
          channelId,
          commentId
        }
      }
    );
    const oldComment = flatComments.find(
      (comment) => commentId === comment.commentId
    );
    setComments({
      comments: [
        {
          ...updatedComment,
          fromUserData: oldComment!.fromUserData
        },
        ...flatComments.filter((comment) => commentId !== comment.commentId)
      ].sort((c1, c2) => c1.createdAt - c2.createdAt),
      isLoading: false
    });
  };

  const editComment = async (commentId: string, content: string, callback?: () => void) => {
    const updatedComment: IComment = await API.post(
      'boxnpaper',
      '/comment/edit',
      {
        body: {
          channelId,
          commentId,
          content
        }
      }
    );
    const oldComment = flatComments.find(
      (comment) => commentId === comment.commentId
    );
    callback?.();
    setComments({
      comments: [
        {
          ...updatedComment,
          fromUserData: oldComment!.fromUserData
        },
        ...flatComments.filter((comment) => commentId !== comment.commentId)
      ].sort((c1, c2) => c1.createdAt - c2.createdAt),
      isLoading: false
    });
  };

  const deleteComment = async (commentId: string) => {
    const updatedComment: IComment = await API.post(
      'boxnpaper',
      '/comment/delete',
      {
        body: {
          channelId,
          commentId,
        }
      }
    );
    const oldComment = flatComments.find(
      (comment) => commentId === comment.commentId
    );
    setComments({
      comments: [
        {
          ...updatedComment,
          fromUserData: oldComment!.fromUserData
        },
        ...flatComments.filter((comment) => commentId !== comment.commentId)
      ].sort((c1, c2) => c1.createdAt - c2.createdAt),
      isLoading: false
    });
  };

  const commentTree = new Map<IComment, Map<IComment, IComment[]>>();
  const rootComments = flatComments.filter((comment) => !comment.replyTo && comment.buyerReview !== true);
  const buyerReviewComment = flatComments.filter((comment) => !comment.replyTo && comment.buyerReview === true);
  for (const rootComment of rootComments) {
    const subTree = new Map<IComment, IComment[]>();
    const branches = flatComments.filter(
      (comment) => comment.replyTo === rootComment.commentId
    );
    for (const branch of branches) {
      let leafs: IComment[] = [];
      let queue = [branch];
      while (queue.length > 0) {
        const node = queue.pop()!;
        const newLeafs = flatComments.filter(
          (comment) => comment.replyTo === node.commentId
        );
        leafs = leafs.concat(newLeafs);
        queue = queue.concat(newLeafs);
      }
      subTree.set(branch, leafs);
    }
    commentTree.set(rootComment, subTree);
  }

  const renderComments = () => {
    return (
    <Box width="100%" paddingBottom={2}>
    {commentTree.size === 0 ? (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={2}
      >
        {buyerReviewComment.length === 0 ? (
        <Typography color={COLORS.blackForty}>
          be the first one to comment!
        </Typography>
        ) : (
          <Typography color={COLORS.blackForty}>
            This item is purchased, no more comments allowed.
          </Typography>
        )}
      </Box>
    ) : (
      Array.from(commentTree, ([rootComment, subTree]) => {
        const expended = !closedMap[rootComment.commentId];
        const toggle = () => {
          const newClosedMap = {
            ...closedMap
          };
          newClosedMap[rootComment.commentId] = expended;
          setClosedMap(newClosedMap);
        };
        let content = null;
        if (!expended) {
          content = (
            <Comment
              indent={0}
              comment={{ ...rootComment, content: '' }}
              isSeller={sellerId === rootComment.from}
              channelId={channelId}
              isLoading={isLoading}
            />
          );
        } else {
          content = (
            <>
              <Comment
                indent={0}
                comment={rootComment}
                isSeller={sellerId === rootComment.from}
                channelId={channelId}
                isLoading={isLoading}
                onReply={(content, commentId, callback) =>
                  postComment(content, commentId, callback)
                }
                onLike={(commentId) => likeComment(commentId)}
                onEdit={(commentId, content, callback) =>
                  editComment(commentId, content, callback)
                }
                onDelete={(commentId) => deleteComment(commentId)}
              />
              {Array.from(subTree, ([branch, leafs]) => {
                const expended = !closedMap[branch.commentId];
                const toggle = () => {
                  const newClosedMap = {
                    ...closedMap
                  };
                  newClosedMap[branch.commentId] = expended;
                  setClosedMap(newClosedMap);
                };
                let content = null;
                if (!expended) {
                  content = (
                    <Comment
                      key={branch.commentId}
                      indent={1}
                      comment={{ ...branch, content: '' }}
                      isSeller={sellerId === branch.from}
                      channelId={channelId}
                      isLoading={isLoading}
                    />
                  );
                } else {
                  content = (
                    <div>
                      <Comment
                        indent={1}
                        comment={branch}
                        isSeller={sellerId === branch.from}
                        channelId={channelId}
                        isLoading={isLoading}
                        onReply={(content, commentId, callback) =>
                          postComment(content, commentId, callback)
                        }
                        onLike={(commentId) => likeComment(commentId)}
                        onEdit={(commentId, content, callback) =>
                          editComment(commentId, content, callback)
                        }
                        onDelete={(commentId) => deleteComment(commentId)}
                      />
                      {leafs.map((leaf) => (
                        <Comment
                          key={leaf.commentId}
                          indent={2}
                          comment={leaf}
                          isSeller={sellerId === leaf.from}
                          channelId={channelId}
                          isLoading={isLoading}
                          onReply={(content, commentId, callback) =>
                            postComment(content, commentId, callback)
                          }
                          onLike={(commentId) => likeComment(commentId)}
                          onEdit={(commentId, content, callback) =>
                            editComment(commentId, content, callback)
                          }
                          onDelete={(commentId) => deleteComment(commentId)}
                        />
                      ))}
                    </div>
                  );
                }
                return (
                  <Accordion
                    key={branch.commentId}
                    expanded={expended}
                    onChange={() => toggle()}
                    disableGutters
                    elevation={0}
                    disabled={branch.deleted}
                    sx={{
                      backgroundColor: COLORS.transparent,
                      '&.MuiAccordion-root:before': {
                        display: 'none'
                      },
                      '&.Mui-disabled': {
                        backgroundColor: COLORS.transparent,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        '&.MuiAccordionSummary-root': { minHeight: 0 },
                        height: expended ? 0 : undefined,
                        top: expended ? '48px' : undefined,
                        padding: 0
                      }}
                    >
                      {expended !== true && content}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        '& .MuiDivider-root': { display: 'none' },
                        padding: 0
                      }}
                    >
                      {content}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          );
        }
        return (
          <div key={rootComment.commentId}>
            <Divider />
            <Accordion
              expanded={expended}
              onChange={() => toggle()}
              disableGutters
              elevation={0}
              disabled={rootComment.deleted}
              sx={{
                backgroundColor: COLORS.transparent,
                '&.Mui-disabled': {
                  backgroundColor: COLORS.transparent,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  '&.MuiAccordionSummary-root': { minHeight: 0 },
                  height: expended ? 0 : undefined,
                  top: expended ? '48px' : undefined,
                  padding: 0
                }}
              >
                {expended !== true && content}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  '& .MuiDivider-root': { display: 'none' },
                  padding: 0
                }}
              >
                {content}
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })
    )}
    <Divider />
  </Box>    
  )
  }

  return (
    <Box
      padding={2}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {buyerReviewComment.length > 0 && (
        <Box width="100%" paddingBottom={2}>
          <Typography
          sx={{
            fontFamily: 'Josefin Sans',
            fontWeight: '700',
            fontSize: '1.5rem'
          }}
          >
            Buyer's Review
          </Typography>
          {buyerReviewComment.map((comment) => (
            <Comment
              key={comment.commentId}
              indent={0}
              comment={comment}
              isSeller={sellerId === comment.from}
              channelId={channelId}
              isLoading={isLoading}
            />
          ))}
        </Box>

      )}
      <Typography
        sx={{
          fontFamily: 'Josefin Sans',
          fontWeight: '700',
          fontSize: '1.5rem'
        }}
      >
        Comments({flatComments.length - buyerReviewComment.length})
      </Typography>
      {comments.isLoading ? (
        <LoadingSpinner />
      ) : (
        refreshCommentsState ? (
          renderComments()
        ) : (
          renderComments()
        )
      )}
      {boxnpaperUser ? (
        buyerReviewComment.length === 0 && (
        <CommentInput
          onPost={(content) => postComment(content)}
          user={{
            name: boxnpaperUser.nickname,
            avatar: boxnpaperUser.profilePhoto
          }}
          isLoading={isLoading}
        />
        )
      ) : (
        <Box textAlign="center" width="100%">
          <Typography textAlign="center">
            <Link href={APP_PATH.ACCOUNT}>Login</Link> to comment
          </Typography>
        </Box>
      )}
    </Box>
  );
});
