import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { IconLogo } from './icons/IconLogo';
import { COLORS } from '../constants/Colors';
import { appStore, IN_APP_NOTIFICATION_STATUS } from '../stores/appStore';
import { IconBell } from './icons/IconBell';
import { IconMenu } from './icons/IconMenu';
import { IconCloseMenu } from './icons/IconCloseMenu';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { IconMobileMenuUser } from './icons/IconMobileMenuUser';
import { IconMobileMenuBuy } from './icons/IconMobileMenuBuy';
import { IconMobileMenuHow } from './icons/IconMobileMenuHow';
import { IconMobileMenuSell } from './icons/IconMobileMenuSell';
import { useNavigate } from 'react-router-dom';
import { APP_PATH } from '../path';
import { observer } from 'mobx-react';
import { IconMessages } from './icons/IconMessages';
import { IconActiveBell } from './icons/IconActiveBell';
import { NotificationRow } from './NotificationRow';
import SendbirdChat, { ConnectionState } from '@sendbird/chat';
import {
  GroupChannelModule,
  SendbirdGroupChat
} from '@sendbird/chat/groupChannel';
import { SENDBIRD_CONFIG } from '../config/sendbird';
import { isAdmin, stage } from '../config/common';
import { IconActiveMessages } from './icons/IconActiveMassages';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

const headerButtonStyle = {
  color: '#000',
  padding: {
    xs: 2,
    lg: 4
  },
  paddingTop: {
    xs: 2,
    lg: 6
  },
  paddingBottom: {
    xs: 2,
    lg: 6
  },
  borderLeftColor: COLORS.blackTen,
  borderLeftStyle: 'solid',
  borderWidth: 1,
  borderRadius: 0,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '30px',
  display: { xs: 'none', lg: 'flex' }
};

const mobileHeaderButtonStyle = {
  borderBottomColor: COLORS.blackTen,
  borderBottomStyle: 'solid',
  borderWidth: 1
};

const Header = () => {
  const { userSignedIn, boxnpaperUser, notifications } = appStore;
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const notificationOpen = Boolean(anchorEl);
  const handleNotificationOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNotificationClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const greeting = `HI, ${boxnpaperUser?.nickname?.toUpperCase() ?? ''}`;
  const hasNewNotification = !!notifications.find(
    (n) => n.status === IN_APP_NOTIFICATION_STATUS.NEW
  );

  const [hasUnreadMessage, setHasUnreadMessage] = useState(false);

  useEffect(() => {
    if (!boxnpaperUser) {
      return;
    }
    const sendbird = SendbirdChat.init({
      appId: SENDBIRD_CONFIG[stage].appId,
      modules: [new GroupChannelModule()]
    }) as SendbirdGroupChat;

    if (sendbird.connectionState !== ConnectionState.CLOSED) {
      return;
    }
    const { userSub, sendbirdAccessToken } = boxnpaperUser;

    sendbird.connect(userSub, sendbirdAccessToken).then(() => {
      sendbird.groupChannel.getTotalUnreadMessageCount().then((count) => {
        if (count > 0) {
          setHasUnreadMessage(true);
        }
      });
    });
  });

  const drawer = (
    <Box
      sx={{
        backgroundColor: '#ffffff00'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          px: 2,
          backgroundColor: '#ffffff00',
          height: 64
        }}
      ></Box>
      <List
        style={{
          boxSizing: 'border-box',
          backgroundColor: '#ffffff',
          marginTop: -1,
          padding: 0
        }}
      >
        <ListItem sx={mobileHeaderButtonStyle}>
          <ListItemButton
            onClick={() => {
              navigate(APP_PATH.HOME);
              window.scrollTo(0, 550);
              handleDrawerToggle();
            }}
          >
            <ListItemIcon style={{ minWidth: 40 }}>
              <IconMobileMenuBuy width="30px" />
            </ListItemIcon>
            <ListItemText primary="BROWSE" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={mobileHeaderButtonStyle}>
          <ListItemButton
            onClick={() => {
              navigate(APP_PATH.SUBMISSION);
              handleDrawerToggle();
            }}
          >
            <ListItemIcon style={{ minWidth: 40 }}>
              <IconMobileMenuSell width="30px" />
            </ListItemIcon>
            <ListItemText primary="SELL" />
          </ListItemButton>
        </ListItem>
        {isAdmin && boxnpaperUser && (
          <ListItem sx={mobileHeaderButtonStyle}>
            <ListItemButton
              onClick={() => {
                navigate(APP_PATH.ADMIN_LISTING);
                handleDrawerToggle();
              }}
            >
              <ListItemIcon style={{ minWidth: 40 }}>
                <Inventory2OutlinedIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="LISTINGS" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                navigate(APP_PATH.FORUM_POST_FORM);
                handleDrawerToggle();
              }}
            >
              <ListItemIcon style={{ minWidth: 40 }}>
                <Inventory2OutlinedIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="NEWS POST" />
            </ListItemButton>
          </ListItem>

        )}
        <ListItem sx={mobileHeaderButtonStyle}>
          <ListItemButton onClick={() => navigate(APP_PATH.FORUM)}>
            <ListItemIcon style={{ minWidth: 40 }}>
              <IconMobileMenuHow width="30px" />
            </ListItemIcon>
            <ListItemText primary="BLOG" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={mobileHeaderButtonStyle}>
          <ListItemButton onClick={() => navigate(APP_PATH.HOW_WE_WORK)}>
            <ListItemIcon style={{ minWidth: 40 }}>
              <IconMobileMenuHow width="30px" />
            </ListItemIcon>
            <ListItemText primary="HOW IT WORKS" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={mobileHeaderButtonStyle}>
          <ListItemButton onClick={() => navigate(APP_PATH.CONTACT)}>
            <ListItemIcon style={{ minWidth: 40 }}>
              <IconMobileMenuHow width="30px" />
            </ListItemIcon>
            <ListItemText primary="CONTACT" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={mobileHeaderButtonStyle}>
          <ListItemButton onClick={() => navigate(APP_PATH.ACCOUNT)}>
            <ListItemIcon style={{ minWidth: 40 }}>
              <IconMobileMenuUser width="30px" />
            </ListItemIcon>
            <ListItemText
              primary={userSignedIn ? greeting : 'LOGIN / CREATE ACCOUNT'}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
// HSS
  return (
    <>
      <AppBar
        position="relative"
        component="nav"
        sx={{
          backgroundColor: '#fff',
          borderBottomColor: COLORS.blackTen,
          borderBottomStyle: 'solid',
          borderWidth: 1,
          boxShadow: 'none',
          zIndex: 10000
        }}
      >
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 0
          }}
        >
          <Box
            sx={{
              padding: {
                xs: 2,
                lg: 5
              },
              paddingRight: {
                xs: 0,
                lg: 0
              },
              flexGrow: 1,
              display: 'flex',
              paddingLeft: { sm: 3 }
            }}
          >
            <Box
              sx={{
                height: { xs: 16, lg: 40 },
                cursor: 'pointer',
                color: '#000'
              }}
              onClick={() => navigate(APP_PATH.HOME)}
            >
              <IconLogo />
            </Box>
          </Box>
          <Box sx={{ flexGrow: 0, display: 'flex' }}>
            <Button
              sx={headerButtonStyle}
              onClick={() => {
                navigate(APP_PATH.HOME);
                window.scrollTo(0, 550);
              }}
            >
              BROWSE
            </Button>
            <Button
              sx={headerButtonStyle}
              onClick={() => {
                navigate(APP_PATH.AUCTION);
              }}
            >
              AUCTION
            </Button>
            <Button
              sx={headerButtonStyle}
              onClick={() => navigate(APP_PATH.SUBMISSION)}
            >
              SELL
            </Button>
            {isAdmin && boxnpaperUser ? (
                <div style={{display: 'flex'}}>
                    <Button
                        sx={headerButtonStyle}
                        onClick={() => navigate(APP_PATH.FORUM_POST_FORM)}
                    >
                        MAKE FORUM POST
                    </Button>
                    <Button
                        sx={headerButtonStyle}
                        onClick={() => {
                        navigate(APP_PATH.ADMIN_LISTING);
                        }}
                    >
                        <Inventory2OutlinedIcon fontSize="large" />
                    </Button>
                </div>
            ) : (
              <>
                <Button
                  sx={headerButtonStyle}
                  onClick={() => navigate(APP_PATH.FORUM)}
                >
                  BLOG
                </Button>
                <Button
                  sx={headerButtonStyle}
                  onClick={() => navigate(APP_PATH.HOW_WE_WORK)}
                >
                  HOW IT WORKS
                </Button>
                <Button
                  sx={headerButtonStyle}
                  onClick={() => navigate(APP_PATH.CONTACT)}
                >
                  CONTACT
                </Button>
              </>
            )}
            <Button
              sx={{
                ...headerButtonStyle,
                display: 'flex'
              }}
              onClick={() => {
                navigate(APP_PATH.MESSAGES);
              }}
            >
              {hasUnreadMessage ? <IconActiveMessages /> : <IconMessages />}
            </Button>
            {userSignedIn && (
              <div>
                <Button
                  sx={{
                    ...headerButtonStyle,
                    display: 'flex'
                  }}
                  onClick={(e) => {
                    if (notifications.length) {
                      handleNotificationOpen(e);
                    }
                  }}
                >
                  {hasNewNotification ? (
                    <IconActiveBell height="30px" />
                  ) : (
                    <IconBell height="30px" />
                  )}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={notificationOpen}
                  onClose={handleNotificationClose}
                  PaperProps={{
                    style: {
                      maxHeight: '60%'
                    }
                  }}
                >
                  {notifications.map((n) => (
                    <MenuItem
                      onClick={handleNotificationClose}
                      key={n.notificationId}
                    >
                      <NotificationRow notification={n} />
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            )}
            <Button
              sx={headerButtonStyle}
              onClick={() => navigate(APP_PATH.ACCOUNT)}
            >
              {userSignedIn ? greeting : 'SIGN IN'}
            </Button>
            <Button
              sx={{
                ...headerButtonStyle,
                display: { lg: 'none' },
                width: 32
              }}
              onClick={handleDrawerToggle}
            >
              {mobileOpen ? (
                <IconCloseMenu height="30px" />
              ) : (
                <IconMenu height="30px" />
              )}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor="top"
          variant="temporary"
          open={mobileOpen}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            backgroundColor: '#00000000',
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              backgroundColor: '#00000000'
            },
            '& .MuiBackdrop-root': {
              boxSizing: 'border-box',
              backgroundColor: '#00000000'
            }
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default observer(Header);
