import {
  Box,
  Divider,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { COLORS } from '../constants/Colors';
import CheckIcon from '@mui/icons-material/Check';

export interface ImageFile {
  cdnUrl: string;
}

export interface ForumPost {
  contentId: string;
  name: string;
  title: string;
  description: string;
  images: ImageFile[];
  createdBy?: string;
  verified?: boolean;
  profilePhoto?: string;
  createdTime: number;
  updatedTime: number;


}

const StyledImg = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  aspectRatio: '1/1',
  padding: '15px'
});

export const getAgeDisplay = (timestamp: number) => {
  const seconds = Math.floor((Date.now() - timestamp) / 1000);
  let interval = seconds / 31536000;
  if (interval > 2) {
    return Math.floor(interval) + ' years ago';
  } else if (interval > 1) {
    return Math.floor(interval) + ' year ago';
  }
  interval = seconds / 2592000;
  if (interval > 2) {
    return Math.floor(interval) + ' months ago';
  } else if (interval > 1) {
    return Math.floor(interval) + ' month ago';
  }
  interval = seconds / 86400;
  if (interval > 2) {
    return Math.floor(interval) + ' days ago';
  } else if (interval > 1) {
    return Math.floor(interval) + ' day ago';
  }
  interval = seconds / 3600;
  if (interval > 2) {
    return Math.floor(interval) + ' hours ago';
  } else if (interval > 1) {
    return Math.floor(interval) + ' hour ago';
  }
  interval = seconds / 60;
  if (interval > 2) {
    return Math.floor(interval) + ' minutes ago';
  } else if (interval > 1) {
    return Math.floor(interval) + ' minute ago';
  }
  return 'Just now';
};

export const ForumPostThumbnail = (props: {
  onThumbnailClick: () => void;
  forumPost: ForumPost;
}) => {
  const {
    // contentId,
    name,
    title,
    images,
    createdTime,
    profilePhoto,
    verified
  } = props.forumPost;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{ backgroundColor: 'white' }}
      style={{ cursor: 'pointer' }}
      onClick={() => props.onThumbnailClick()}
    >
      <Box
        p={{ xs: 1, sm: 1.5 }}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          {profilePhoto && (
            <img
              src={profilePhoto}
              style={{
                width: '2em',
                height: '2em',
                borderRadius: '1.5em',
                marginRight: '1em'
              }}
            />
          )}
          <Box>
            <Typography
              variant={isMobile ? 'body2' : 'body1'}
              sx={{ lineHeight: '125%', fontSize: '14px' }}
            >
              {name ?? ''}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              sx={{
                color: COLORS.blackSixty,
                fontSize: '10px',
                lineHeight: '125%'
              }}
            >
              {getAgeDisplay(createdTime)}
            </Typography>
          </Box>
        </Box>
        {verified && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <CheckIcon fontSize="small" color="success" />
            <Typography
              sx={{
                fontFamily: 'Poppins',
                color: COLORS.blackSixty,
                fontSize: '12px'
              }}
            >
              Verified
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />
      <StyledImg src={images.length ? `${images[0].cdnUrl}-/quality/smart/-/format/auto/` : ''} />
      <Divider />
      <Box p={{ xs: 1, sm: 1.5 }}>
        <Typography
          variant={isMobile ? 'body2' : 'body1'}
          gutterBottom
          sx={{
            fontWeight: '500',
            lineHeight: '125%',
            // whiteSpace: 'nowrap',
            whiteSpace: 'normal',
            textOverflow: 'ellipsis',
            // overflow: 'hidden'
          }}
        >
          {title ?? ''}
        </Typography>
        {/* <Typography
          sx={{
            lineHeight: '125%',
            color: COLORS.blackSixty,
            fontSize: '12px'
          }}
        >
          Few words of description goes here...
        </Typography> */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
        </Box>
      </Box>
    </Box>
  );
};
