export const IconActiveBell = (props: { height: string }) => (
  <svg
    height={props.height}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1421_8404)">
      <path
        d="M4.84825 25.3361V10.7019C4.84825 5.04435 9.43459 0.458008 15.0922 0.458008C20.7497 0.458008 25.3361 5.04435 25.3361 10.7019V25.3361M0.458008 25.3361H29.7263"
        stroke="black"
        strokeWidth="0.916667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0921 29.7272C17.1127 29.7272 18.7507 28.0892 18.7507 26.0686V25.3369H11.4336V26.0686C11.4336 28.0892 13.0716 29.7272 15.0921 29.7272Z"
        stroke="black"
        strokeWidth="0.916667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9277 8.59277C26.1369 8.59277 27.9277 6.80191 27.9277 4.59277C27.9277 2.38363 26.1369 0.592773 23.9277 0.592773C21.7186 0.592773 19.9277 2.38363 19.9277 4.59277C19.9277 6.80191 21.7186 8.59277 23.9277 8.59277Z"
        fill="#FF4747"
      />
    </g>
    <defs>
      <clipPath id="clip0_1421_8404">
        <rect width="31" height="31" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
