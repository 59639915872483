const isAdmin = () =>  window.location.pathname.slice(0, 6) === "/admin"

export const APP_PATH = {
    HOME: isAdmin() ? '/admin' : '/',
    ACCOUNT: isAdmin() ? '/admin/account' : '/account',
    AUCTION: isAdmin() ? '/admin/auction' : '/auction',
    SUBMISSION: isAdmin() ? '/admin/submission' : '/submission',
    // SUBMISSION: '/admin/submission',
    WATCH_DETAIL: isAdmin() ? '/admin/watch/:sku' : '/watch/:sku',
    AUCTION_WATCH_DETAIL: isAdmin() ? '/admin/auctionwatch/:sku' : '/auctionwatch/:sku',
    EDIT_SUBMISSION: isAdmin() ? '/admin/edit/watch/:sku' : '/edit/watch/:sku',
    USER_DASHBOARD: isAdmin() ? '/admin/user-dashboard/:uid' : '/user-dashboard/:uid',
    RESET_PASSWORD: isAdmin() ? '/admin/reset-password' : '/reset-password',
    MESSAGES: isAdmin() ? '/admin/messages' : '/messages',
    ADMIN_LISTING: '/admin/listing',
    ADMIN_SUBMISSION_DETAIL: '/admin/submission/:sku',
    ADMIN_PUBLISH_WATCH: '/admin/publish/:sku',
    HOW_WE_WORK: isAdmin() ? '/admin/how-it-works' : '/how-it-works',
    PRIVACY_POLICY: isAdmin() ? '/admin/privacy-policy' : '/privacy-policy',
    CONTACT: isAdmin() ? '/admin/contact' : '/contact',
    TERMS_CONDITIONS: isAdmin() ? '/admin/terms-conditions' : '/terms-conditions',
    FORUM_POST_FORM: isAdmin() ? '/admin/forum-post-form' : '/forum-post-form',
    FORUM: isAdmin() ? '/admin/forum' : '/forum',
    FORUM_POST_DETAIL: isAdmin() ? '/admin/forum-post/:contentId' : '/forum-post/:contentId'
};
