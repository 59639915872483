import { Box, CircularProgress, styled } from '@mui/material';
import { COLORS } from '../constants/Colors';

export const LoadingSpinner = (props: { color?: string, size?: number | string }) => (
  <SpinnerContainer>
    <StyledCircularProgress size={props.size} customcolor={props.color} />
  </SpinnerContainer>
);

const StyledCircularProgress = styled(CircularProgress)<{
  customcolor?: string;
}>`
  color: ${(props) => props.customcolor ?? COLORS.blackSixty};
`;

const SpinnerContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;
