import {
  Typography,
  Box,
  Link,
  useTheme,
  useMediaQuery
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { ArrowForwardIcon } from './icons/Form-arrow-icon';
import { useState } from 'react';
import { API } from 'aws-amplify';
import { COLORS } from '../constants/Colors';
import { APP_PATH } from '../path';
import { useNavigate } from 'react-router-dom';

const footerLinks = {
  color: 'white',
  textDecoration: 'none',
  fontFamily: 'Lato',
  fontWeight: '400',
  marginLeft: '1.25em',
  textTransform: 'none',
  fontSize: '16px',
  cursor: 'pointer'
};

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [emailFormInfo, setEmailFormInfo] = useState({
    email: '',
    error: false,
    message: ''
  });

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: COLORS.black,
        paddingTop: 4,
        display: 'flex',
        flexDirection: 'column',
        color: COLORS.white
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          paddingLeft: {
            xs: '1.25em',
            md: '3em'
          }
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: '2rem',
            marginBottom: '0.3em',
            fontFamily: 'Poppins',
            fontWeight: '800',
            textTransform: 'uppercase',
            sx: {
              fontSize: '1.5rem'
            }
          }}
        >
          Box & Paper
        </Typography>
      </Box>
      <Box
        sx={{
          color: COLORS.white,
          paddingTop: 4,
          paddingLeft: 4,
          paddingRight: 4,
          alignSelf: 'flex-end'
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Josefin Sans',
            fontSize: '1.5rem',
            marginBottom: '12px'
          }}
        >
          Stay up to date!
        </Typography>

        <FormControl fullWidth>
          <OutlinedInput
            sx={{
              color: '#fff',
              borderStyle: 'solid',
              borderRadius: 50,
              height: 42,
              marginTop: '1em',
              input: {
                '&::placeholder': {
                  opacity: 1,
                  fontSize: '14px'
                }
              },
              minWidth: '320px'
            }}
            placeholder="Enter your email"
            type="email"
            value={emailFormInfo.email}
            required={true}
            onChange={(e) =>
              setEmailFormInfo({
                ...emailFormInfo,
                email: e.target.value,
                message: '',
                error: false
              })
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={async () => {
                    if (
                      !emailFormInfo.email ||
                      !validateEmail(emailFormInfo.email)
                    ) {
                      setEmailFormInfo({
                        ...emailFormInfo,
                        message: 'Please enter a valid email address',
                        error: true
                      });
                    } else {
                      try {
                        await API.post('boxnpaper', '/subscription', {
                          body: {
                            email: emailFormInfo.email
                          }
                        });
                      } catch (e) {
                        // silence is gold
                      }
                      setEmailFormInfo({
                        ...emailFormInfo,
                        message: 'Subbscribed Sucessfully',
                        error: false,
                        email: '',
                      });
                    }
                  }}
                  edge="end"
                >
                  <ArrowForwardIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography
            sx={{
              margin: '4px',
              color: emailFormInfo.error ? '#e57373' : '#81c784',
              fontSize: '12px'
            }}
          >
            {emailFormInfo.message}
          </Typography>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          margin: 'auto',
          color: 'white',
          justifyContent: 'space-between',
          width: '100%',
          padding: 2,
          paddingTop: 6
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            width: isMobile ? '100%' : undefined,
            marginTop: isMobile ? 2 : 0,
            textAlign: 'center'
          }}
        >
          @2023 BOX & PAPER. TRADEMARK PENDING
        </Typography>
        <Box
          sx={{
            display: 'flex',
            fontSize: '1rem',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Link
            href="https://www.instagram.com/boxnpaper/"
            target="_blank"
            sx={{
              ...footerLinks,
              display: 'flex',
              fontSize: '12px',
              marginRight: '1.25em',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <InstagramIcon fontSize="small" sx={{ marginRight: 1 }} />
            Instagram
          </Link>
          <Link
            sx={{
              ...footerLinks,
              fontSize: '12px',
              marginRight: '1.25em'
            }}
            onClick={() => navigate(APP_PATH.PRIVACY_POLICY)}
          >
            Privacy Policy
          </Link>
          <Link
            onClick={() => navigate(APP_PATH.TERMS_CONDITIONS)}
            sx={{
              ...footerLinks,
              fontSize: '12px',
              marginRight: '1.25em'
            }}
          >
            Terms & Conditions
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
