export const COLORS = {
  whiteNinty: 'rgba(255, 255, 255, 0.9)',
  blackSixty: 'rgba(0, 0, 0, 0.6)',
  blackForty: 'rgba(0, 0, 0, 0.4)',
  blackTwelve: 'rgba(0, 0, 0, 0.12)',
  blackTen: 'rgba(0, 0, 0, 0.1)',
  blackFour: 'rgba(0, 0, 0, 0.04)',
  blackOne: 'rgba(0, 0, 0, 0.01)',
  grey98: '#FAFAFA',
  black: '#000',
  white: '#fff',
  transparent: '#00000000',
  lightGrey: '#F0F0F0',
  lightGreen: '#B1EFC3',
  lightOrange: '#FFD6AF',
  warning: '#FF4747',
};
