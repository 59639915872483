export const IconMenu = (props: { height: string }) => (
  <svg
    height={props.height}
    viewBox="0 0 30 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      style={{
        mixBlendMode: 'difference'
      }}
    >
      <line y1="0.5" x2="30" y2="0.5" stroke="white" />
      <line y1="12.5" x2="30" y2="12.5" stroke="white" />
      <line y1="24.5" x2="30" y2="24.5" stroke="white" />
    </g>
  </svg>
);
