import { Box, Container } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import Header from '../../components/Header';
import { COLORS } from '../../constants/Colors';
import { withRouter, WithRouterProps } from '../../utils/withRouter';
import ForumPosts from './ForumPosts';
import Footer from '../../components/Footer';

@observer
class Home extends React.Component<WithRouterProps<{}>> {
  render() {
    return (
      <>
        <Header />
        <Box
          style={{
            display: 'flex',
            height: 500,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#000',
            color: COLORS.grey98,
            textAlign: 'center',
            overflow: 'hidden'
          }}
        >
          <img
            style={{
              height: '70%',
              transform: 'scale(2)'
            }}
            className="image-zoom"
            src="https://ucarecdn.com/de770c96-bbb6-4d1f-a785-d277d211cb94/-/quality/smart/-/format/auto/"
            alt='Home collage'
          />
        </Box>
        <Container sx={{ minHeight: '80vh', paddingTop: { xs: 4, lg: 10 } }}>
          <ForumPosts />
        </Container>
        <Footer />
      </>
    );
  }
}

export default withRouter(Home);
