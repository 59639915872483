import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PageContainer } from '../../components/Containers';
import Header from '../../components/Header';
import { LoadingSpinner } from '../../components/LoadingSpinner';


import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { APP_PATH } from '../../path';
import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  useTheme,
  useMediaQuery,
  ImageList,
  ImageListItem,

} from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ImageViewer from 'react-simple-image-viewer';
import { ChatIcon } from '../../components/icons/icon-chat';
import Footer from '../../components/Footer';
import { COLORS } from '../../constants/Colors';
import CheckIcon from '@mui/icons-material/Check';



import { styled } from '@mui/system';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Comments } from '../../components/comments/Comments';

import { ForumPost } from '../../components/ForumPostThumbnail';



function ForumPostDetails() {
  let { contentId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState<ForumPost | null>(null);
  const [imageViewer, setImageViewer] = useState({
    open: false,
    index: 0
  });

//   const { userSignedIn, boxnpaperUser } = appStore;


  useEffect(() => {
    const getWatchAndDeals = async () => {
      setLoading(true);
      try {
        const data = await API.get('boxnpaper', '/content/post', {
          queryStringParameters: {
            contentId
          }
        });
        setPost(data.post);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    getWatchAndDeals();
  }, [contentId]);

  if (loading || !post) {
    return (
      <>
        <Header />
        <PageContainer sx={{ height: '100vh' }}>
          <LoadingSpinner />
        </PageContainer>
      </>
    );
  }

  function formatTimestampToDateTime(timestamp: number): string {
    const date = new Date(timestamp);
  
    // Define the options type explicitly
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    };
  
    // Format the date and time
    const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(date);
  
    return formattedDateTime;
  }
  
  // Example usage:
  const timestamp = 1703432939535;
  const formattedDateTime = formatTimestampToDateTime(timestamp);
  console.log(formattedDateTime);
  

  const renderWatchImages = () => {
    if (isMobile && post.images.length) {
      return (
        <Grid container>
          <img
            src={post.images[imageViewer.index].cdnUrl}
            width="100%"
            height="100%"
            alt={post.title}
            onClick={() =>
              setImageViewer({
                ...imageViewer,
                open: !imageViewer.open
              })
            }
            style={{
              borderStyle: 'solid',
              color: COLORS.blackTen,
              borderWidth: '1px',
              cursor: 'pointer',
              objectFit: 'cover'
            }}
          />
          <ImageList
            sx={{
              gridAutoFlow: 'column',
              gridTemplateColumns:
                'repeat(auto-fit, minmax(80px,1fr)) !important',
              gridAutoColumns: 'minmax(80px, 1fr)'
            }}
          >
            {post.images.map((img, index) => (
              <ImageListItem key={index}>
                <img
                  alt={post.title}
                  src={`${img.cdnUrl}-/quality/smart/-/format/auto/`}
                  onClick={() =>
                    setImageViewer({
                      ...imageViewer,
                      index
                    })
                  }
                  style={{
                    borderStyle: 'solid',
                    color: COLORS.blackTen,
                    borderWidth: '1px',
                    cursor: 'pointer',
                    objectFit: 'cover',
                    maxWidth: '80px'
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      );
    }
    return (
      <>
        <Grid container>
          {post.images.map((img, index) => {
            return (
              <Grid
                item
                key={index}
                xs={index === 0 ? 12 : 6}
                paddingBottom={1}
                paddingLeft={index === 0 || index % 2 === 1 ? 0 : 1}
              >
                <img
                  alt={post.title}
                  src={`${img.cdnUrl}-/quality/smart/-/format/auto/`}
                  width="100%"
                  height="100%"
                  onClick={() =>
                    setImageViewer({
                      open: !imageViewer.open,
                      index
                    })
                  }
                  style={{
                    borderStyle: 'solid',
                    color: COLORS.blackTen,
                    borderWidth: '1px',
                    cursor: 'pointer',
                    objectFit: 'cover'
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  };

  const renderWatchTitle = () => {
    return (
      <Box
        sx={{
          textAlign: {
            xs: 'center',
            sm: 'left'
          }
        }}
      >
        <Typography
          sx={{
            fontSize: '28px',
            fontFamily: 'Josefin Sans',
            fontWeight: '700'
          }}
        >
          {post.title} 
        </Typography>
        {/* <Typography>
         {watch.viewCount != null ? watch.viewCount : 0} views
        </Typography> */}
        <Typography
          sx={{
            fontSize: '14px',
            fontFamily: 'Poppins',
            color: COLORS.blackSixty
          }}
        >
          {/* {post.contentId} */}
          {formatTimestampToDateTime(post.createdTime)}
        </Typography>

      </Box>
    );
  };



//   const renderActionModal = () => {
//     return (
//       <Modal
//         sx={{ zIndex: 10000 }}
//         open={modalActive.isOpen}
//         onClose={() => {
//           setModalActive({
//             tab: MODAL_TAB.BUY_IT_NOW,
//             isOpen: false
//           });
//         }}
//       >
//         <Box sx={modalStyle}>
//           <Box>{renderBoxContent()}</Box>
//         </Box>
//       </Modal>
//     );
//   };

  const renderSellerInfo = () => {
    return (
      <Box
        sx={{
          margin: '24px 0em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            textAlign: 'left',
            alignItems: 'center',
            marginRight: '6px',
            cursor: 'pointer'
          }}
          onClick={() => {
            if (post.createdBy) {
              navigate(APP_PATH.USER_DASHBOARD.replace(':uid', post.createdBy));
            }
          }}
        >
          {post.profilePhoto ? (
            <img
              src={post.profilePhoto}
              style={{
                width: '2.5em',
                height: '2.5em',
                borderRadius: '2.5em'
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: '2.5em',
                height: '2.5em'
              }}
            />
          )}
          <Box
            sx={{
              paddingLeft: '6px',
              display: 'flex',
              flexDirection: 'column', // Display name and verification status in a row
              alignItems: 'center', // Align items in the center
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: '600',
                fontSize: {
                  xs: '14px',
                  md: '16px'
                }
              }}
            >
              {post.name ?? ''} 
      
            </Typography>
            {post.verified === true && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginLeft: '6px', // Add some space between name and checkmark
                }}
              >
                <CheckIcon fontSize="small" color="success" />
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    color: COLORS.blackSixty,
                    fontSize: '12px'
                  }}
                >
                  Verified
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
  
        <Grid>
          <ThinButton
            variant="outlined"
            onClick={() =>
              navigate(`${APP_PATH.MESSAGES}?chatwith=${post.createdBy}`)
            }
          >
            <ChatIcon /> CHAT NOW
          </ThinButton>
        </Grid>
      </Box>
    );
  };
  

  const renderWatchAccordions = () => {
    const watchAccordions = [
      {
        label: 'Description',
        content: post.description
      }
    ];
    return (
      <Box
        sx={{
          marginTop: '1em'
        }}
      >
        {watchAccordions.map((accordion) => (
          <Accordion
            square
            sx={{
              boxShadow: 'none',
              backgroundColor: COLORS.transparent
            }}
            key={accordion.label}
            expanded
          >
            <AccordionSummary
              sx={{
                padding: '0'
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Josefin Sans',
                  fontWeight: '700',
                  fontSize: '1.15rem'
                }}
              >
                {/* {accordion.label} */}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div dangerouslySetInnerHTML={{ __html: accordion.content }} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  return (
    <>
      <Header />
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: COLORS.transparent,
          padding: '12px'
        }}
      >
        <Grid item xs={12} padding={isMobile ? 1 : 2}>
          <Breadcrumbs
            sx={{
              marginTop: '1em'
            }}
            separator=">"
          >
            <Link href={APP_PATH.HOME}>Home</Link>
            <Typography color="text.primary">Forum Post {contentId}</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid container marginTop={1}>
          <Grid item sm={6} xs={12} padding={isMobile ? 1 : 2}>
            {renderWatchImages()}
          </Grid>
          <Grid item sm={6} xs={12} padding={isMobile ? 1 : 2}>
            {renderWatchTitle()}
            {renderSellerInfo()}
            {renderWatchAccordions()}
          </Grid>
        </Grid>
        <Comments sellerId={post.createdBy!} channelId={post.contentId} />
      </Container>
      <Footer />
      {imageViewer.open && (
        <ImageViewer
          src={post.images.map((i) => i.cdnUrl)}
          currentIndex={imageViewer.index}
          onClose={() =>
            setImageViewer({
              open: false,
              index: 0
            })
          }
          disableScroll
          backgroundStyle={{
            backgroundColor: COLORS.whiteNinty,
            zIndex: 10000 // header is 9999
          }}
          closeOnClickOutside
          rightArrowComponent={
            <ArrowForwardIcon fontSize="large" color="primary" />
          }
          leftArrowComponent={
            <ArrowBackIcon fontSize="large" color="primary" />
          }
        />
      )}
    </>
  );
}

const ThinButton = styled(Button)({
  borderRadius: '2rem',
  gap: '0.4em',
  fontFamily: 'Poppins',
  whiteSpace: 'nowrap',
  fontSize: '12px',
  height: '28px'
});

export default ForumPostDetails;
