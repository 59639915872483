export const IconMobileMenuUser = (props: { width: string }) => (
  <svg
    width={props.width}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 15C0 6.71572 6.71572 0 15 0C23.2843 0 30 6.71572 30 15C30 19.1861 28.2853 22.9716 25.5199 25.6926C25.4967 25.7208 25.4704 25.7465 25.4416 25.7691C22.7412 28.3879 19.0588 30 15 30C10.9676 30 7.30689 28.4089 4.61137 25.8202C4.54296 25.7795 4.48538 25.7231 4.44329 25.6563C1.69952 22.938 0 19.1674 0 15ZM5.38625 25.1773C7.89404 27.547 11.2773 29 15 29C18.7232 29 22.107 27.5466 24.6149 25.1762C24.2657 21.7766 21.3927 19.1245 17.9006 19.1245H12.1006C8.60816 19.1245 5.73492 21.7772 5.38625 25.1773ZM25.4856 24.2767C24.749 20.7629 21.6331 18.1245 17.9006 18.1245H12.1006C8.3677 18.1245 5.25153 20.7635 4.51543 24.2778C2.32797 21.8077 1 18.5589 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15C29 18.5584 27.6724 21.8067 25.4856 24.2767ZM10.8763 10.6504C10.8763 8.37229 12.7232 6.52547 15.0013 6.52547C17.2794 6.52547 19.1263 8.37229 19.1263 10.6504C19.1263 12.9286 17.2794 14.7754 15.0013 14.7754C12.7232 14.7754 10.8763 12.9286 10.8763 10.6504ZM15.0013 7.52547C13.2754 7.52547 11.8763 8.92458 11.8763 10.6504C11.8763 12.3763 13.2754 13.7754 15.0013 13.7754C16.7271 13.7754 18.1263 12.3763 18.1263 10.6504C18.1263 8.92458 16.7271 7.52547 15.0013 7.52547Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 15C0 6.71572 6.71572 0 15 0C23.2843 0 30 6.71572 30 15C30 19.1861 28.2853 22.9716 25.5199 25.6926C25.4967 25.7208 25.4704 25.7465 25.4416 25.7691C22.7412 28.3879 19.0588 30 15 30C10.9676 30 7.30689 28.4089 4.61137 25.8202C4.54296 25.7795 4.48538 25.7231 4.44329 25.6563C1.69952 22.938 0 19.1674 0 15ZM5.38625 25.1773C7.89404 27.547 11.2773 29 15 29C18.7232 29 22.107 27.5466 24.6149 25.1762C24.2657 21.7766 21.3927 19.1245 17.9006 19.1245H12.1006C8.60816 19.1245 5.73492 21.7772 5.38625 25.1773ZM25.4856 24.2767C24.749 20.7629 21.6331 18.1245 17.9006 18.1245H12.1006C8.3677 18.1245 5.25153 20.7635 4.51543 24.2778C2.32797 21.8077 1 18.5589 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15C29 18.5584 27.6724 21.8067 25.4856 24.2767ZM10.8763 10.6504C10.8763 8.37229 12.7232 6.52547 15.0013 6.52547C17.2794 6.52547 19.1263 8.37229 19.1263 10.6504C19.1263 12.9286 17.2794 14.7754 15.0013 14.7754C12.7232 14.7754 10.8763 12.9286 10.8763 10.6504ZM15.0013 7.52547C13.2754 7.52547 11.8763 8.92458 11.8763 10.6504C11.8763 12.3763 13.2754 13.7754 15.0013 13.7754C16.7271 13.7754 18.1263 12.3763 18.1263 10.6504C18.1263 8.92458 16.7271 7.52547 15.0013 7.52547Z"
      fill="black"
    />
  </svg>
);
