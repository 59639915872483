export const IconMobileMenuBuy = (props: { width: string }) => (
  <svg
    width={props.width}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7705 19.9004L20.0011 29.0004H10.2011L7.43164 19.9004"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.7705 19.9004L20.0011 29.0004H10.2011L7.43164 19.9004"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.43164 10.1L10.2011 1H20.0011L22.7705 10.1"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.43164 10.1L10.2011 1H20.0011L22.7705 10.1"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.2 15.0004C24.2 16.8045 23.675 18.486 22.7695 19.9004C21.1523 22.4262 18.3217 24.1004 15.1 24.1004C11.8783 24.1004 9.04766 22.4262 7.43052 19.9004C6.525 18.486 6 16.8045 6 15.0004C6 13.1963 6.525 11.5148 7.43052 10.1004C9.04766 7.57458 11.8783 5.90039 15.1 5.90039C18.3217 5.90039 21.1523 7.57458 22.7695 10.1004C23.675 11.5148 24.2 13.1963 24.2 15.0004Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.2 15.0004C24.2 16.8045 23.675 18.486 22.7695 19.9004C21.1523 22.4262 18.3217 24.1004 15.1 24.1004C11.8783 24.1004 9.04766 22.4262 7.43052 19.9004C6.525 18.486 6 16.8045 6 15.0004C6 13.1963 6.525 11.5148 7.43052 10.1004C9.04766 7.57458 11.8783 5.90039 15.1 5.90039C18.3217 5.90039 21.1523 7.57458 22.7695 10.1004C23.675 11.5148 24.2 13.1963 24.2 15.0004Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0999 10.0996V14.9996L17.8999 17.7996"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0999 10.0996V14.9996L17.8999 17.7996"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
