export const IconBell = (props: { height: string }) => (
  <svg
    height={props.height}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.12185 11.1096C6.12185 5.70515 10.503 1.32402 15.9074 1.32402C21.3119 1.32402 25.693 5.70515 25.693 11.1096V25.2854H6.12185V11.1096ZM26.6097 11.1096V25.2854H30.5416C30.7947 25.2854 30.9999 25.4906 30.9999 25.7437C30.9999 25.9969 30.7947 26.2021 30.5416 26.2021H26.1513H20.0243V26.4757C20.0243 28.7494 18.1811 30.5926 15.9074 30.5926C13.6338 30.5926 11.7906 28.7494 11.7906 26.4757V26.2021H5.66352H1.27327C1.02014 26.2021 0.814941 25.9969 0.814941 25.7437C0.814941 25.4906 1.02014 25.2854 1.27327 25.2854H5.20519V11.1096C5.20519 5.19889 9.99673 0.407349 15.9074 0.407349C21.8181 0.407349 26.6097 5.19889 26.6097 11.1096ZM12.7072 26.4757V26.2023H19.1076V26.4757C19.1076 28.2431 17.6748 29.6759 15.9074 29.6759C14.14 29.6759 12.7072 28.2431 12.7072 26.4757Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.12185 11.1096C6.12185 5.70515 10.503 1.32402 15.9074 1.32402C21.3119 1.32402 25.693 5.70515 25.693 11.1096V25.2854H6.12185V11.1096ZM26.6097 11.1096V25.2854H30.5416C30.7947 25.2854 30.9999 25.4906 30.9999 25.7437C30.9999 25.9969 30.7947 26.2021 30.5416 26.2021H26.1513H20.0243V26.4757C20.0243 28.7494 18.1811 30.5926 15.9074 30.5926C13.6338 30.5926 11.7906 28.7494 11.7906 26.4757V26.2021H5.66352H1.27327C1.02014 26.2021 0.814941 25.9969 0.814941 25.7437C0.814941 25.4906 1.02014 25.2854 1.27327 25.2854H5.20519V11.1096C5.20519 5.19889 9.99673 0.407349 15.9074 0.407349C21.8181 0.407349 26.6097 5.19889 26.6097 11.1096ZM12.7072 26.4757V26.2023H19.1076V26.4757C19.1076 28.2431 17.6748 29.6759 15.9074 29.6759C14.14 29.6759 12.7072 28.2431 12.7072 26.4757Z"
      fill="black"
    />
  </svg>
);
