import {
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  Typography,
  useMediaQuery,
  useTheme,
  MenuItem
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../constants/Colors';
import { APP_PATH } from '../../path';
import { appStore } from '../../stores/appStore';
import { getAgeDisplay } from '../WatchThumbnail';
import { CommentInput } from './CommentInput';
import { IComment } from './Comments';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { isAdmin } from '../../config/common';

export interface ICommentProps {
  comment: IComment;
  indent: 0 | 1 | 2;
  isSeller: boolean;
  channelId: string;
  isLoading: boolean;
  onReply?: (
    replyContent: string,
    replyTo: string,
    callback: () => void
  ) => void;
  onLike?: (commentId: string) => void;
  onEdit?: (commentId: string, content: string, callback: () => void) => void;
  onDelete?: (commentId: string) => void;
}

export const Comment = (props: ICommentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [reply, setReply] = useState(false);

  const {
    comment,
    indent,
    isSeller,
    onReply,
    isLoading,
    onLike,
    onEdit,
    onDelete
  } = props;
  const {
    content,
    fromUserData,
    createdAt,
    commentId,
    likedBy,
    from,
    editedBy,
    deleted,
    deletedByAdmin
  } = comment;

  const { nickname, profilePhoto, userSub } = fromUserData;
  const currentUserId = appStore.boxnpaperUser?.userSub;
  const isAuthor = currentUserId === from;
  const indentUnitWidth = isMobile ? 3 : 6;
  const likeCount = likedBy ? likedBy.length : 0;
  const liked =
    currentUserId && likedBy && likedBy.includes(currentUserId) ? true : false;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [editing, setEditing] = useState(false);

  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="flex-start"
        alignItems="flex-start"
        marginTop={content ? 4 : 2}
        marginBottom={content ? 4 : 2}
        paddingLeft={isMobile ? indentUnitWidth * indent : 0}
        paddingRight={content ? 3 : 0}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          minWidth="320px"
          paddingLeft={isMobile ? 0 : indentUnitWidth * indent}
        >
          <img
            src={profilePhoto}
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '48px',
              marginRight: '12px',
              cursor: 'pointer'
            }}
            onClick={() =>
              navigate(APP_PATH.USER_DASHBOARD.replace(':uid', userSub))
            }
          />
          <Box
            display="flex"
            flexDirection={isMobile ? 'row' : 'column'}
            alignItems={isMobile ? 'center' : 'flex-start'}
            justifyContent="flex-start"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              onClick={() =>
                navigate(APP_PATH.USER_DASHBOARD.replace(':uid', userSub))
              }
            >
              <Typography fontWeight={500}>
                {nickname}
              </Typography>
              <Typography fontSize="12px" fontWeight="100">
                {getAgeDisplay(createdAt)}
              </Typography>
            </Box>
            {isSeller && (
              <Chip
                label="SELLER"
                style={{
                  backgroundColor: COLORS.black,
                  color: COLORS.white,
                  height: '24px',
                  fontSize: '12px',
                  marginLeft: isMobile ? '12px' : '0px',
                  fontWeight: 600
                }}
              />
            )}
          </Box>
        </Box>
        <Box width="100%">
          {deleted ? (
            <Typography
              sx={{
                fontSize: '12px',
                color: COLORS.blackForty,
                marginTop: isMobile ? 1 : 0,
              }}
            >
              Comment deleted {deletedByAdmin ? 'by admin' : 'by auther'}
            </Typography>
          ) : (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              {editing && onEdit ? (
                <CommentInput
                  isLoading={isLoading}
                  onPost={(content) =>
                    onEdit(commentId, content, () => {
                      setEditing(false);
                    })
                  }
                  onCancel={() => setEditing(false)}
                  defaultContent={content}
                />
              ) : (
                <Typography
                  minHeight={isMobile ? 0 : '60px'}
                  marginTop={isMobile ? '16px' : 0}
                >
                  {content}
                  {editedBy && content && (
                    <span
                      style={{
                        fontSize: '12px',
                        color: COLORS.blackForty,
                        marginLeft: '8px'
                      }}
                    >
                      edited
                    </span>
                  )}
                </Typography>
              )}
              {(isAdmin || isAuthor) && content && onDelete && (
                <>
                  <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: '20ch'
                      }
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setEditing(true);
                        handleClose();
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        onDelete(commentId);
                        handleClose();
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          )}
          {currentUserId && onReply && onLike && !deleted && (
            <>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
                marginTop="12px"
              >
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: '24px',
                    fontWeight: 300
                  }}
                  onClick={() => setReply(!reply)}
                >
                  {reply ? 'CANCEL' : 'REPLY'}
                </Button>
                <IconButton
                  style={{
                    marginLeft: '16px'
                  }}
                  onClick={() => onLike(commentId)}
                >
                  {liked ? (
                    <FavoriteIcon color="warning" />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </IconButton>
                <Typography fontSize="14px" color={COLORS.blackForty}>
                  {likeCount > 0 ? likeCount : ''}
                </Typography>
              </Box>
              {reply && (
                <CommentInput
                  onPost={(content) =>
                    onReply(content, commentId, () => setReply(false))
                  }
                  isLoading={isLoading}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
