import {
  Box,
  Divider,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { COLORS } from '../constants/Colors';
import { getBoxPaperDescription } from '../utils/watch';
import { IconLocation } from './icons/IconLocation';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

export interface ImageFile {
  cdnUrl: string;
}

export enum WATCH_STATUS {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  RECEIVED = 'RECEIVED',
  PUBLISHED = 'PUBLISHED',
  SALE_PENDING = 'SALE_PENDING',
  SOLD = 'SOLD',
  SOLD_IN_STORE = 'SOLD_IN_STORE',
  ARCHIVED = 'ARCHIVED',
  REJECTED = 'REJECTED',
  FAILED_AUCTION = 'FAILED_AUCTION'
}

export const WATCH_STATUS_NAME = {
  PENDING_APPROVAL: 'Pending Approval',
  APPROVED: 'Approved',
  RECEIVED: 'Received',
  PUBLISHED: 'Published',
  SOLD_IN_STORE: 'Sold in store',
  SOLD: 'Sold',
  SALE_PENDING: 'Sale pending',
  ARCHIVED: 'Archived',
  REJECTED: 'Rejected',
  FAILED_AUCTION: 'Failed Auction'
};

export interface WatchListing {
  sku: string;
  brand: string;
  model: string;
  serialNumber: string;
  yearOfProduction: number;
  hasBox: boolean;
  hasPaper: boolean;
  workCondition: boolean;
  termsConditions: boolean;
  additionalDetails?: string;
  targetPrice: number;
  images: ImageFile[];
  name?: string;
  profilePhoto?: string;
  email: string;
  street: string;
  zipCode: string;
  city: string;
  state: string;
  phone: string;
  submissionType: 'SELL_TO_US' | 'CONSIGN' | 'BOTH';
  createdTime: number;
  updatedTime: number;
  status: WATCH_STATUS;
  adminNotes?: string;
  createdBy?: string;
  description?: string;
  condition?: string;
  dimension?: number;
  height?: number;
  lugWidth?: number;
  grading?: string;
  soldInStorePrice?: number;
  verified?: boolean;
  banned?: boolean;
  location: string;
  shipping: string;
  paymentMethods: string;
  viewCount: number;
  favoriteCount: number;
  favorite: boolean;
  transactionCount: number;
  lastBidPrice: number;
  lastBidCreatedBy: string;
  reserveMet: boolean;
  hasReserve: boolean;
  bidEndTime: number;
  isAuction: boolean;
}

const StyledImg = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  aspectRatio: '1/1',
  padding: '15px'
});

export const getAgeDisplay = (timestamp: number) => {
  const seconds = Math.floor((Date.now() - timestamp) / 1000);
  let interval = seconds / 31536000;
  if (interval > 2) {
    return Math.floor(interval) + ' years ago';
  } else if (interval > 1) {
    return Math.floor(interval) + ' year ago';
  }
  interval = seconds / 2592000;
  if (interval > 2) {
    return Math.floor(interval) + ' months ago';
  } else if (interval > 1) {
    return Math.floor(interval) + ' month ago';
  }
  interval = seconds / 86400;
  if (interval > 2) {
    return Math.floor(interval) + ' days ago';
  } else if (interval > 1) {
    return Math.floor(interval) + ' day ago';
  }
  interval = seconds / 3600;
  if (interval > 2) {
    return Math.floor(interval) + ' hours ago';
  } else if (interval > 1) {
    return Math.floor(interval) + ' hour ago';
  }
  interval = seconds / 60;
  if (interval > 2) {
    return Math.floor(interval) + ' minutes ago';
  } else if (interval > 1) {
    return Math.floor(interval) + ' minute ago';
  }
  return 'Just now';
};

export const WatchThumbnail = (props: {
  onThumbnailClick: () => void;
  watchListing: WatchListing;
}) => {
  const {
    name,
    images,
    brand,
    model,
    state,
    targetPrice,
    createdTime,
    profilePhoto,
    verified,
    banned,
    transactionCount
  } = props.watchListing;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{ backgroundColor: 'white' }}
      style={{ cursor: 'pointer' }}
      onClick={() => props.onThumbnailClick()}
    >
      <Box
        p={{ xs: 1, sm: 1.5 }}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          {profilePhoto && (
            <img
              src={profilePhoto}
              style={{
                width: '2em',
                height: '2em',
                borderRadius: '1.5em',
                marginRight: '1em'
              }}
            />
          )}
          <Box>

            <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              <Typography
                variant={isMobile ? 'body2' : 'body1'}
                sx={{ lineHeight: '125%', fontSize: '14px' }}
              >
                {name ?? ''}
              </Typography>
              <Box
                sx={{
                  width: '4px',
                }}
              >
              </Box>
              <Typography
                variant={isMobile ? 'body2' : 'body1'}
                sx={{ lineHeight: '125%', fontSize: '14px', color: 'lightgray'  }}
              >
                ({transactionCount ?? '0'})
              </Typography>
            </Box>
            
            <Typography
              variant="caption"
              display="block"
              sx={{
                color: COLORS.blackSixty,
                fontSize: '10px',
                lineHeight: '125%'
              }}
            >
              {getAgeDisplay(createdTime)}
            </Typography>
          </Box>
        </Box>
        {false && verified && banned === false && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <CheckIcon fontSize="small" color="success" />
            <Typography
              sx={{
                fontFamily: 'Poppins',
                color: COLORS.blackSixty,
                fontSize: '12px'
              }}
            >
              Verified
            </Typography>
          </Box>
        )}
        {banned == true && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <DoDisturbIcon fontSize="small" sx={{color: 'red'}} />
            <Typography
              sx={{
                fontFamily: 'Poppins',
                color: COLORS.blackSixty,
                fontSize: '12px'
              }}
            >
              Banned
            </Typography>
          </Box>
        )}
      </Box>
      <Divider />
      {images.length > 0 ? (
        <StyledImg src={`${images[0].cdnUrl}-/quality/smart/-/format/auto/`} />
      ) : (
        <StyledImg src="/images/watch-placeholder.svg" />
      )}
      <Divider />
      <Box p={{ xs: 1, sm: 1.5 }}>
        <Typography
          variant={isMobile ? 'body2' : 'body1'}
          gutterBottom
          sx={{
            fontWeight: '500',
            lineHeight: '125%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {brand} {model}
        </Typography>
        <Typography
          sx={{
            lineHeight: '125%',
            color: COLORS.blackSixty,
            fontSize: '12px'
          }}
        >
          {getBoxPaperDescription(props.watchListing)}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box display="flex" justifyContent="center">
            <IconLocation />
            <Typography
              variant="body2"
              sx={{
                lineHeight: '125%',
                fontSize: '12px',
                color: COLORS.blackSixty,
                marginLeft: '5px'
              }}
            >
              {state}
            </Typography>
          </Box>
          <Typography variant={isMobile ? 'body2' : 'body1'}>
            {targetPrice.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0
            })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
