import { Button } from '@mui/material';
import styled from 'styled-components';

export const FormButton = styled(Button)`
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-weight: bold !important;
  border-radius: 100px !important;
`;

export const RoundBorderButton = styled(Button)`
  border-radius: 2rem !important;
`;
