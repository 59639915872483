export const IconCloseMenu = (props: { height: string }) => (
  <svg
    height={props.height}
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="1.74711" y1="1.04" x2="22.9603" y2="22.2532" stroke="white" />
    <line x1="1.74711" y1="1.04" x2="22.9603" y2="22.2532" stroke="black" />
    <line x1="1.03976" y1="22.2534" x2="22.253" y2="1.04018" stroke="white" />
    <line x1="1.03976" y1="22.2534" x2="22.253" y2="1.04018" stroke="black" />
  </svg>
);
