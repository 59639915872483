import { action, computed, makeObservable, observable } from 'mobx';
import { API, Auth } from 'aws-amplify';
import {
  CognitoHostedUIIdentityProvider,
  CognitoUser
} from '@aws-amplify/auth';

export enum APP_ERROR_KEYS {
  LOGIN = 'LOGIN'
}

export interface BoxnpaperUserAttributes {
  given_name: string;
}

export enum GENDERS {
  MALE = 'Male',
  FEMALE = 'Female'
}

export enum IN_APP_NOTIFICATION_STATUS {
  NEW = 'NEW',
  SEEN = 'SEEN'
}

export interface IBoxnpaperUser {
  userSub: string;
  email: string;
  givenName: string;
  familyName: string;
  nickname: string;
  bio: string;
  middleName: string;
  sendbirdAccessToken: string;
  profilePhoto: string;
  dateOfBirth: string;
  gender: string;
  phoneNumber: string;
  createdTime: string;
  verified: boolean;
  banned: boolean;
  smsNotification: boolean;
  emailNotification: boolean;
}

export interface INotification {
  userSub: string;
  notificationId: string;
  title: string;
  subtitle: string;
  image: string;
  link: string;
  notificationType: string;
  createdAt: number;
  status: IN_APP_NOTIFICATION_STATUS;
}

class AppStore {
  @observable testCount: number = 0;
  @observable cognitoUser:
    | (CognitoUser & { attributes: BoxnpaperUserAttributes })
    | null = null;
  @observable boxnpaperUser: IBoxnpaperUser | null = null;
  @observable loading: boolean = false;
  @observable watchSubmitted: boolean = false;
  @observable forumPostSubmitted: boolean = false;
  @observable errors: { [key: string]: string } = {};
  @observable notifications: INotification[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  loadNotifications = async (callback?: () => void) => {
    this.loading = true;
    try {
      const notifications: INotification[] = await API.get(
        'boxnpaper',
        '/notifications',
        {}
      );
      this.setNotifications(notifications);
      if (callback) callback();
    } catch (e) {
      console.log('errer', e);
    }
    this.loading = false;
  };

  @action
  setNotificationSeen = async (notification: INotification, callback?: () => void) => {
    this.loading = true;
    try {
      await API.post(
        'boxnpaper',
        '/notifications/seen',
        {
          body: notification
        }
      );
      notification.status = IN_APP_NOTIFICATION_STATUS.SEEN;
      this.setNotifications(this.notifications);
      if (callback) callback();
    } catch (e) {
      console.log('errer', e);
    }
    this.loading = false;
  };

  @action
  setNotifications = (notifications: INotification[]) => {
    this.notifications = notifications.sort((n1, n2) => {
      if (n1.status === n2.status) {
        return n2.createdAt - n1.createdAt;
      }
      if (n2.status === IN_APP_NOTIFICATION_STATUS.NEW) return 1;
      return -1;
    });
  }

  @action
  clearNotifications = () => {
    this.notifications = [];
  };

  @action
  submitWatch = async (watch: any, callback?: () => void) => {
    this.loading = true;
    try {
      await API.post('boxnpaper', '/watch/submission', {
        body: watch
      });
      if (callback) callback();
    } catch (e) {
      console.log('errer', e);
    }
    this.watchSubmitted = true;
    this.loading = false;
  };

  @action
  submitForumPost = async (forumPost: any, callback?: () => void) => {
    this.loading = true;
    try {
      await API.post('boxnpaper', '/content/post', {
        body: forumPost
      });
      if (callback) callback();
    } catch (e) {
      console.log('errer', e);
    }
    this.forumPostSubmitted = true;
    this.loading = false;
  }

  @action
  updateWatch = async (watch: any, callback?: () => void) => {
    this.loading = true;
    try {
      await API.post('boxnpaper', '/watch/submission/update', {
        body: watch
      });
      if (callback) callback();
    } catch (e) {
      console.log('errer', e);
    }
    this.watchSubmitted = true;
    this.loading = false;
  };

  @computed
  get userSignedIn() {
    return !!this.cognitoUser;
  }

  @action
  setCognitoUser(user: CognitoUser | null) {
    this.cognitoUser = user as CognitoUser & {
      attributes: BoxnpaperUserAttributes;
    };
  }

  @action
  setBoxnpaperUser(boxnpaperUser: IBoxnpaperUser | null) {
    this.boxnpaperUser = boxnpaperUser;
  }

  @action
  addOne() {
    this.testCount++;
  }

  @action
  async signOut(path?: string) {
    try {
      await Auth.signOut();
      if (path) {
        window.location.replace(path);
      }
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  @action
  signin = async (email: string, pwd: string) => {
    this.loading = true;
    this.clearError();
    try {
      await Auth.signIn(email, pwd);
    } catch (error) {
      console.log('error signing in', error);
      this.errors[APP_ERROR_KEYS.LOGIN] = 'Incorrect email or password.';
    }
    this.loading = false;
  };

  @action
  signinWithFacebook = () => {
    try {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook
      });
    } catch (error) {
      console.log('error signing in', error);
    }
  };

  @action
  clearError = () => {
    this.errors = {};
  };
}

export const appStore = new AppStore();
