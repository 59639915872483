export interface boxnpaperCognitoConfig {
    userPoolId: string;
    clientId: string;
    adminUserPoolId: string;
    adminClientId: string;
};

export const COGNITO_CONFIG: { [key: string]: boxnpaperCognitoConfig } = {
    dev: {
        userPoolId: "us-east-1_aS3lcypz4",
        clientId: "3kd6pc449aoaarp80rbj261aku",
        adminUserPoolId: "us-east-1_njsvo8b1H",
        adminClientId: "10ngrk9i7atoard9gbmhc3845h",
    },
    alpha: {
        userPoolId: "us-east-1_aS3lcypz4",
        clientId: "3kd6pc449aoaarp80rbj261aku",
        adminUserPoolId: "us-east-1_njsvo8b1H",
        adminClientId: "10ngrk9i7atoard9gbmhc3845h",
    },
    beta: {
        userPoolId: "us-east-1_E4JDNjurU",
        clientId: "31mbhin3lfk2d5ldfjm7hf6n0c",
        adminUserPoolId: "us-east-1_ce9HPqCAN",
        adminClientId: "2iti9chk6gs03bttrm9ttn9k4e",
    },
    gamma: {
        userPoolId: "us-east-1_2wFDBU04m",
        clientId: "2e88scksru9lli1p1bb9e8n3id",
        adminUserPoolId: "us-east-1_PujgjLULm",
        adminClientId: "2qrmham0edcn711v4o7camba6g",
    },
    prod: {
        userPoolId: "us-east-1_EG6JJKMZc",
        clientId: "242fvb22v3cdnv0d9qquvf9uhn",
        adminUserPoolId: "us-east-1_ixB1jRhLt",
        adminClientId: "7ndro0nn2fg7ggeglbjml1b669",
    },
}