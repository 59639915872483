export const IconMobileMenuSell = (props: { width: string }) => (
  <svg
    width={props.width}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_115_2654)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0448 1C15.3209 1 15.5448 1.22386 15.5448 1.5V5.36315H25.2251H25.2254H25.2256C25.2476 5.36315 25.2693 5.36459 25.2907 5.3674C25.3179 5.37097 25.3446 5.37675 25.3705 5.38459C25.4877 5.42009 25.5896 5.49799 25.6543 5.6059L30.0176 12.878C30.0676 12.9613 30.0905 13.0533 30.0889 13.1438C30.0844 15.8259 27.9087 17.9988 25.2256 17.9988C22.5425 17.9988 20.3668 15.8259 20.3623 13.1439C20.3607 13.0534 20.3836 12.9613 20.4336 12.878L24.3425 6.36315H15.5448V27.1798H25.2251C25.5013 27.1798 25.7251 27.4037 25.7251 27.6798C25.7251 27.9559 25.5013 28.1798 25.2251 28.1798H15.0442H4.86323C4.58708 28.1798 4.36323 27.9559 4.36323 27.6798C4.36323 27.4037 4.58708 27.1798 4.86323 27.1798H14.5448V6.36315H5.74643L9.65535 12.878C9.70534 12.9613 9.72824 13.0534 9.7266 13.1438C9.7221 15.8259 7.54646 17.9988 4.86334 17.9988C2.18022 17.9988 0.00459432 15.8259 8.25995e-05 13.1439C-0.0015623 13.0534 0.0213316 12.9613 0.0713291 12.878L4.42757 5.61761C4.51337 5.4657 4.67632 5.36315 4.86323 5.36315H4.86334H14.5448V1.5C14.5448 1.22386 14.7687 1 15.0448 1ZM8.34366 12.6355L4.86334 6.83498L1.38302 12.6355H8.34366ZM4.86334 16.9988C2.89915 16.9988 1.27733 15.5329 1.03213 13.6355H8.69454C8.44934 15.5329 6.82752 16.9988 4.86334 16.9988ZM28.7059 12.6355L25.2256 6.83498L21.7453 12.6355H28.7059ZM25.2256 16.9988C23.2614 16.9988 21.6396 15.5329 21.3944 13.6355H29.0568C28.8116 15.5329 27.1898 16.9988 25.2256 16.9988Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0448 1C15.3209 1 15.5448 1.22386 15.5448 1.5V5.36315H25.2251H25.2254H25.2256C25.2476 5.36315 25.2693 5.36459 25.2907 5.3674C25.3179 5.37097 25.3446 5.37675 25.3705 5.38459C25.4877 5.42009 25.5896 5.49799 25.6543 5.6059L30.0176 12.878C30.0676 12.9613 30.0905 13.0533 30.0889 13.1438C30.0844 15.8259 27.9087 17.9988 25.2256 17.9988C22.5425 17.9988 20.3668 15.8259 20.3623 13.1439C20.3607 13.0534 20.3836 12.9613 20.4336 12.878L24.3425 6.36315H15.5448V27.1798H25.2251C25.5013 27.1798 25.7251 27.4037 25.7251 27.6798C25.7251 27.9559 25.5013 28.1798 25.2251 28.1798H15.0442H4.86323C4.58708 28.1798 4.36323 27.9559 4.36323 27.6798C4.36323 27.4037 4.58708 27.1798 4.86323 27.1798H14.5448V6.36315H5.74643L9.65535 12.878C9.70534 12.9613 9.72824 13.0534 9.7266 13.1438C9.7221 15.8259 7.54646 17.9988 4.86334 17.9988C2.18022 17.9988 0.00459432 15.8259 8.25995e-05 13.1439C-0.0015623 13.0534 0.0213316 12.9613 0.0713291 12.878L4.42757 5.61761C4.51337 5.4657 4.67632 5.36315 4.86323 5.36315H4.86334H14.5448V1.5C14.5448 1.22386 14.7687 1 15.0448 1ZM8.34366 12.6355L4.86334 6.83498L1.38302 12.6355H8.34366ZM4.86334 16.9988C2.89915 16.9988 1.27733 15.5329 1.03213 13.6355H8.69454C8.44934 15.5329 6.82752 16.9988 4.86334 16.9988ZM28.7059 12.6355L25.2256 6.83498L21.7453 12.6355H28.7059ZM25.2256 16.9988C23.2614 16.9988 21.6396 15.5329 21.3944 13.6355H29.0568C28.8116 15.5329 27.1898 16.9988 25.2256 16.9988Z"
        fill="black"
      />
    </g>
  </svg>
);
