import { WatchListing } from "../components/WatchThumbnail";

export const getBoxPaperDescription = (watch: WatchListing) => {
  return watch.hasBox && watch.hasPaper
    ? 'Box and Papers'
    : watch.hasBox
    ? 'Box'
    : watch.hasPaper
    ? 'Papers'
    : 'no Box or Papers';
};
