import { Auth } from "aws-amplify"
import { APP_PATH } from "../path"
import { API_CONFIG } from "./api"
import { COGNITO_CONFIG } from "./cognito"
import { DOMAIN_STAGE_MAP } from "./web";

export const domainName = window.location.hostname;

export const isAdmin = window.location.pathname.slice(0, 6) === "/admin";

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export const stage = DOMAIN_STAGE_MAP[domainName];

const endpoints = [
  {
    name: "boxnpaper",
    endpoint: API_CONFIG[stage].boxnpaperApi.endpoint,
    region: "us-east-1",
    custom_header: async () => {
      try {
        return {
          'X-API-Key': API_CONFIG[stage].boxnpaperApi.apiKey,
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      } catch (e) {
        return {
          'X-API-Key': API_CONFIG[stage].boxnpaperApi.apiKey,
        }
      }
    }
  }
];

export const REGULAR_AMPLIFY_CONFIG = {
  Auth: {
    userPoolId: COGNITO_CONFIG[stage ?? 'alpha'].userPoolId,
    userPoolWebClientId: COGNITO_CONFIG[stage ?? 'alpha'].clientId,
    region: "us-east-1",
  },
  oauth: {
    domain: `boxnpaper-${isLocalhost ? 'alpha' : stage}.auth.us-east-1.amazoncognito.com`,
    redirectSignIn: isLocalhost ? `http://localhost:3000/account/` : `https://${domainName}${APP_PATH.ACCOUNT}/`,
    redirectSignOut: isLocalhost ? 'http://localhost:3000/' : `https://${domainName}/`,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    responseType: "code"
  },
  endpoints,
}

export const ADMIN_AMPLIFY_CONFIG = {
  Auth: {
    userPoolId: COGNITO_CONFIG[stage ?? 'alpha'].adminUserPoolId,
    userPoolWebClientId: COGNITO_CONFIG[stage ?? 'alpha'].adminClientId,
    region: "us-east-1",
  },
  oauth: {
    domain: `boxnpaper-admin-${isLocalhost ? 'alpha' : stage}.auth.us-east-1.amazoncognito.com`,
    redirectSignIn: isLocalhost ? `http://localhost:3000/account/` : `https://${domainName}${APP_PATH.ACCOUNT}/`,
    redirectSignOut: isLocalhost ? 'http://localhost:3000/' : `https://${domainName}/`,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    responseType: "code"
  },
  endpoints,
}