const TEMP_API_ID = "4C7901F7-2F1D-483E-A207-6C74F6B6596C";

//const ORIG_API_TOKEN = "8b52d6cf49663b8210731a125804dd6abb15aa9e";


export const SENDBIRD_CONFIG: { [key: string]: { appId: string } } = {
    dev: {
        appId: TEMP_API_ID,
    },
    alpha: {
        appId: TEMP_API_ID,
    },
    beta: {
        appId: "39018EEA-B4F8-40CC-B4F5-B878B315A083",
    },
    gamma: {
        appId: "39018EEA-B4F8-40CC-B4F5-B878B315A083",
    },
    prod: {
        appId: "1C9B7842-51F8-4521-B8EE-813BC251394B",
    },
}