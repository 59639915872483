export const IconLocation = () => (
  <svg
    width="9"
    height="13"
    viewBox="0 0 9 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.03753 12.2579C0.632109 7.32104 0 6.81437 0 5C0 2.51471 2.01471 0.5 4.5 0.5C6.98529 0.5 9 2.51471 9 5C9 6.81437 8.36789 7.32104 4.96247 12.2579C4.73899 12.5807 4.26098 12.5807 4.03753 12.2579ZM4.5 6.875C5.53554 6.875 6.375 6.03554 6.375 5C6.375 3.96446 5.53554 3.125 4.5 3.125C3.46446 3.125 2.625 3.96446 2.625 5C2.625 6.03554 3.46446 6.875 4.5 6.875Z"
      fill="black"
      fillOpacity="0.6"
    />
  </svg>
);
