import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { COLORS } from '../../constants/Colors';
import { FormButton } from '../Buttons';

export interface ICommentInputProps {
  onPost: (content: string) => void;
  user?: {
    name: string;
    avatar: string;
  };
  defaultContent?: string;
  onCancel?: () => void;
  isLoading: boolean;
}

const maxCommentLength = 300;

export const CommentInput = (props: ICommentInputProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { onPost, user, isLoading, defaultContent, onCancel } = props;
  const [content, setContent] = useState(defaultContent ?? '');

  const isContentValid =
    content.length <= maxCommentLength &&
    (!defaultContent || defaultContent !== content);

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      marginTop="12px"
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-around"
      >
        {user && !isMobile && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            minWidth="240px"
          >
            <img
              src={user.avatar}
              style={{
                width: '48px',
                height: '48px',
                borderRadius: '48px',
                marginRight: '24px'
              }}
            />
            <Typography fontWeight={500}>{user.name}</Typography>
          </Box>
        )}
        <TextField
          fullWidth
          rows={4}
          multiline
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
        />
      </Box>
      {content.length > maxCommentLength && (
        <Typography
          width="100%"
          fontSize="12px"
          marginTop={1}
          textAlign={isMobile ? 'center' : 'right'}
          style={{
            color: COLORS.warning
          }}
        >
          Post shorten your comments. (maximun {maxCommentLength} charactors)
        </Typography>
      )}
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        marginTop={4}
      >
        {onCancel && (
          <Button
            variant="text"
            onClick={() => {
              onCancel();
            }}
          >
            {isLoading ? <CircularProgress size="24px" /> : 'CANCEL'}
          </Button>
        )}
        <FormButton
          variant="contained"
          disabled={!content.length || !isContentValid}
          onClick={() => {
            if (isContentValid) {
              onPost(content);
              setContent('');
            }
          }}
          sx={{ marginLeft: 1 }}
        >
          {isLoading ? <CircularProgress size="24px" /> : 'POST'}
        </FormButton>
      </Box>
    </Box>
  );
};
