import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isAdmin } from '../config/common';
import { COLORS } from '../constants/Colors';
import { appStore, INotification, IN_APP_NOTIFICATION_STATUS } from '../stores/appStore';
import { IconNewMsgDot } from './icons/IconNewMsgDot';
import { getAgeDisplay } from './WatchThumbnail';

export const NotificationRow = (props: { notification: INotification }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setNotificationSeen } = appStore;
  const { notification } = props;
  const { title, subtitle, image, link, createdAt, status } = notification;

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'flex-end' : 'center'}
      justifyContent="space-between"
      width="100%"
      style={{
        cursor: 'pointer'
      }}
      onClick={async () => {
        await setNotificationSeen(notification);
        navigate(
          `${isAdmin ? '/admin' : ''}/${link.split('/').slice(1).join('/')}`
        );
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        maxWidth="100%"
      >
        {status === IN_APP_NOTIFICATION_STATUS.NEW && (
          <Box paddingRight="12px">
            <IconNewMsgDot />
          </Box>
        )}
        {image && (
          <img
            style={{
              borderStyle: 'solid',
              borderColor: COLORS.blackTen,
              borderRadius: '12px',
              borderWidth: '1px'
            }}
            src={image}
            width={isMobile ? '48px' : '100px'}
            height={isMobile ? '48px' : '100px'}
          />
        )}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          paddingLeft="12px"
          overflow="overlay"
        >
          <Typography noWrap>{title}</Typography>
          <Typography fontSize="12px" color={COLORS.blackSixty} noWrap>
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Typography paddingLeft="24px" fontSize="14px">
        {getAgeDisplay(createdAt)}
      </Typography>
    </Box>
  );
};
