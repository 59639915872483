import React, { Suspense, useEffect } from 'react';
import { Amplify, API, Auth, Hub } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { APP_PATH } from './path';
import { appStore, IBoxnpaperUser } from './stores/appStore';
import {
  ADMIN_AMPLIFY_CONFIG,
  isAdmin,
  REGULAR_AMPLIFY_CONFIG
} from './config/common';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { COLORS } from './constants/Colors';
import Forum from './pages/Forum';
import ForumPostDetails from './pages/Forum/ForumPostDetails';
const Home = React.lazy(() => import('./pages/Home'));
const Auction = React.lazy(() => import('./pages/Auction'));
const Account = React.lazy(() => import('./pages/Account'));
const Submission = React.lazy(() => import('./pages/Submission'));
const WatchDetail = React.lazy(() => import('./pages/WatchDetail'));
const AuctionWatchDetail = React.lazy(() => import('./pages/AuctionWatchDetail'));
const EditSubmission = React.lazy(() => import('./pages/EditSubmission'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));
const UserDashboard = React.lazy(() => import('./pages/UserDashboard.tsx'));
const Listing = React.lazy(() => import('./pages/Listing'));
const SubmissionDetail = React.lazy(() => import('./pages/SubmissionDetail'));
const ChatPage = React.lazy(() => import('./pages/Chat'));
const PublishWatch = React.lazy(() => import('./pages/PublishWatch'));
const HowWeWork = React.lazy(() => import('./pages/HowWeWork'));
const ForumPostForm = React.lazy(() => import('./pages/ForumPostForm'));

const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const TermCondition = React.lazy(() => import('./pages/TermCondition'));
const Contact = React.lazy(() => import('./pages/Contact'));

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.black,
      light: COLORS.blackForty,
      dark: COLORS.blackSixty
    }
  },
  typography: {
    fontFamily: ['Poppins'].join(',')
  }
});

Amplify.configure(isAdmin ? ADMIN_AMPLIFY_CONFIG : REGULAR_AMPLIFY_CONFIG);

const App = () => {
  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          const cognitoData = await getCognitoUser();
          appStore.setCognitoUser(cognitoData);
          const boxnpaperUser = await getBoxnpaperUser();
          appStore.setBoxnpaperUser(boxnpaperUser);
          appStore.loadNotifications();
          window.location.replace(APP_PATH.HOME);
          break;
        case 'signOut':
          appStore.setCognitoUser(null);
          appStore.setBoxnpaperUser(null);
          appStore.clearNotifications();
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getCognitoUser().then((cognitoData) =>
      appStore.setCognitoUser(cognitoData)
    );
    getBoxnpaperUser().then((boxnpaperUser) =>
      appStore.setBoxnpaperUser(boxnpaperUser)
    );
    appStore.loadNotifications();
  }, []);

  async function getCognitoUser() {
    try {
      const cognitoData = await Auth.currentAuthenticatedUser();
      return cognitoData;
    } catch {}
  }

  async function getBoxnpaperUser() {
    const boxnpaperUser = await API.get('boxnpaper', '/user/get', {});
    return boxnpaperUser as IBoxnpaperUser;
  }

  return (
    <ThemeProvider theme={theme}>
      <Authenticator.Provider>
        <BrowserRouter>
          <Routes>
            <Route
              path={APP_PATH.ACCOUNT}
              element={
                <Suspense fallback={null}>
                  <Account />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.HOME}
              element={
                <Suspense fallback={null}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.AUCTION}
              element={
                <Suspense fallback={null}>
                  <Auction />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.SUBMISSION}
              element={
                <Suspense fallback={null}>
                  <Submission />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.WATCH_DETAIL}
              element={
                <Suspense fallback={null}>
                  <WatchDetail />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.AUCTION_WATCH_DETAIL}
              element={
                <Suspense fallback={null}>
                  <AuctionWatchDetail />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.FORUM_POST_DETAIL}
              element={
                <Suspense fallback={null}>
                  <ForumPostDetails />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.EDIT_SUBMISSION}
              element={
                <Suspense fallback={null}>
                  <EditSubmission />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.USER_DASHBOARD}
              element={
                <Suspense fallback={null}>
                  <UserDashboard />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.MESSAGES}
              element={
                <Suspense fallback={null}>
                  <ChatPage />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.RESET_PASSWORD}
              element={
                <Suspense fallback={null}>
                  <ResetPassword />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.CONTACT}
              element={
                <Suspense fallback={null}>
                  <Contact />
                </Suspense>
              }
            />
            <Route
                path={APP_PATH.FORUM_POST_FORM}
                element={
                    <Suspense fallback={null}>
                    <ForumPostForm />
                    </Suspense>
                }
            />
            <Route
                path={APP_PATH.FORUM}
                element={
                    <Suspense fallback={null}>
                    <Forum />
                    </Suspense>
                }
            />
            <Route
              path={APP_PATH.HOW_WE_WORK}
              element={
                <Suspense fallback={null}>
                  <HowWeWork />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.PRIVACY_POLICY}
              element={
                <Suspense fallback={null}>
                  <PrivacyPolicy />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.TERMS_CONDITIONS}
              element={
                <Suspense fallback={null}>
                  <TermCondition />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.ADMIN_LISTING}
              element={
                <Suspense fallback={null}>
                  <Listing />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.ADMIN_SUBMISSION_DETAIL}
              element={
                <Suspense fallback={null}>
                  <SubmissionDetail />
                </Suspense>
              }
            />
            <Route
              path={APP_PATH.ADMIN_PUBLISH_WATCH}
              element={
                <Suspense fallback={null}>
                  <PublishWatch />
                </Suspense>
              }
            />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </Authenticator.Provider>
    </ThemeProvider>
  );
};

export default App;
